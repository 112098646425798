import Image from 'next/image'
import Link from 'next/link'

export const AppFooter = () => {
  return (
    <div className="footer_container">
      <div className="footer_about">
        <div className="about_us">
          <div className="about_logo">
            <Image src="/images/boom_logo.png" alt="Boom logo" layout="fill" objectFit="contain" />
          </div>
          <div className="about_info">Copyright © 2021, BoomTV</div>
        </div>
        <div className="about_links">
          <Link href="/terms">
            <a className="about_link">Terms and Conditions</a>
          </Link>
          <Link href="/privacy">
            <a className="about_link">Privacy</a>
          </Link>
          <Link href="/about">
            <a className="about_link">About</a>
          </Link>
          <Link href="/personal-data">
            <a className="about_link">Do Not Sell My Personal Information</a>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AppFooter
