import { useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import Image from 'next/image'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { useAppContext } from 'contexts/app_context'
import { useViewport } from 'contexts/viewport'
import { selectors as globalSelectors } from 'redux/global_slice'
import { selectors as lobbySelectors } from 'redux/event_lobby_slice'
import { AppScreens } from 'utils/constants'

export const AppHeader = ({ transparent = false }) => {
  const { asPath } = useRouter()
  const { isTwoColumnView, width } = useViewport()
  const [state, configDispatch] = useAppContext()
  const [isScrolling, setIsScrolling] = useState(false)
  const [isShowingHamburger, setIsShowingHamburger] = useState(false)
  const currentScreen = useSelector(globalSelectors.currentScreen)
  const eventTitle = useSelector(lobbySelectors.eventTitle)

  const goToHomePage = () => {
    window.location.href = '/'
  }

  const confirmGoToHomePage = () => {
    if (currentScreen === AppScreens.EventLobby || currentScreen === AppScreens.GamePlay) {
      configDispatch({
        type: 'SET_CONFIRM_MESSAGE',
        payload: 'Are you sure you want to return to the home page?',
      })
      configDispatch({
        type: 'SHOW_MODAL_CONFIRM',
        payload: true,
      })
      configDispatch({
        type: 'SET_CONFIRM_ACTION',
        payload: goToHomePage,
      })
    }
  }

  const showModalJoinOuting = () => {
    configDispatch({
      type: 'SHOW_MODAL_JOIN_OUTING',
      payload: true,
    })
  }

  const isEventTitleVisible = useMemo(() => {
    return (
      currentScreen &&
      currentScreen !== AppScreens.Landing &&
      (isTwoColumnView || currentScreen === AppScreens.GamePlay)
    )
  }, [isTwoColumnView, currentScreen])

  const toggleNavHamburger = () => {
    setIsShowingHamburger(!isShowingHamburger)
  }

  useEffect(() => {
    const handleScroll = e => {
      var offset = window.pageYOffset || document.documentElement.scrollTop
      if (offset > 0) {
        setIsScrolling(true)
      } else {
        setIsScrolling(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const isWhiteNavbar = !transparent || isScrolling || isShowingHamburger

  const isSmallLogoNeeded = eventTitle?.length > 20 && width < 650 && currentScreen === AppScreens.GamePlay

  return (
    <div className={'nav_header' + (isWhiteNavbar ? ' white' : '')}>
      <div className="container nav_header_inner">
        <div className="block_left">
          <div className={cx('header_logo', { small: isSmallLogoNeeded })}>
            <Link
              passHref
              href={
                currentScreen === AppScreens.EventLobby || currentScreen === AppScreens.GamePlay
                  ? 'javascript:void(0)'
                  : '/'
              }
            >
              <Image
                src={
                  isSmallLogoNeeded
                    ? '/images/modal_logo.png'
                    : isWhiteNavbar
                    ? '/images/header/logo_white.png'
                    : '/images/header/logo.png'
                }
                alt="Logo"
                layout="fill"
                objectFit="contain"
                onClick={confirmGoToHomePage}
              />
            </Link>
          </div>
          {isEventTitleVisible && (
            <span className="nav_logo_text">{isSmallLogoNeeded ? `${eventTitle.slice(0, 16)}...` : eventTitle}</span>
          )}
        </div>
        <div className="block_right">
          {width > 650 ? (
            <div className="nav_menu_container">
              <div className="nav_menu">
                <Link href="/help-details">
                  <a>Help & Details</a>
                </Link>
              </div>
              <div className="nav_menu">
                <Link href="/contact-us">
                  <a>Contact</a>
                </Link>
              </div>
              <div className="nav_menu">
                <div className="nav_button" onClick={showModalJoinOuting}>
                  <span>Create Now</span>
                  <div className="arrow_button" />
                </div>
              </div>
            </div>
          ) : (
            <div className="nav_menu_container">
              {isWhiteNavbar ? (
                <Image
                  src="/images/header/hamburger_grey.png"
                  alt="Hamburger"
                  width={27}
                  height={23}
                  onClick={toggleNavHamburger}
                />
              ) : (
                <Image
                  src="/images/header/hamburger.png"
                  alt="Hamburger"
                  width={27}
                  height={23}
                  onClick={toggleNavHamburger}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {width <= 650 && (
        <div className={'nav_mobile_menu' + (isShowingHamburger ? ' show' : '')}>
          <div className="nav_shadow" />
          <div className="nav_mobile_section">
            <Link href="/help-details">
              <a>Help & Details</a>
            </Link>
          </div>
          <div className="nav_mobile_section">
            <Link href="/contact-us">
              <a>Contact</a>
            </Link>
          </div>
          <div className="nav_mobile_button" onClick={showModalJoinOuting}>
            Create Now
          </div>
        </div>
      )}
    </div>
  )
}

export default AppHeader
