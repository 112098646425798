import Image from 'next/image'
import { useState } from 'react'
import { useAppContext } from 'contexts/app_context'
import Cookies from 'js-cookie'
import IconClose from '../icon/close'
import styles from 'styles/modal_confirm.module.css'

export default function ModalJoinOuting() {
  const [displayName, setDisplayName] = useState('')
  const [state, dispatch] = useAppContext()
  const { showModalJoinOuting, passcodeModalJoinOuting } = state

  const closeModalJoinOuting = () => {
    dispatch({ type: 'SHOW_MODAL_JOIN_OUTING', payload: false })
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      submit()
    }
  }

  const submit = () => {
    var inSixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000)
    Cookies.set('displayName', displayName?.trim(), {
      expires: inSixtyMinutes,
    })
    if (displayName?.trim() && passcodeModalJoinOuting) {
      window.location.href = `/events/${passcodeModalJoinOuting}`
    } else {
      closeModalJoinOuting()
      if (displayName?.trim() && window.location.pathname !== '/landing') {
        window.location.href = '/landing'
      }
    }
  }

  return (
    <div className={styles.modal_message + (showModalJoinOuting ? '' : ' hide')} id="modal_join_outing">
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <div className={styles.modal_logo}>
            <Image src="/images/modal_logo.png" alt="Team Games Logo" width={88} height={48} />
          </div>
          <div className={styles.modal_title}>Join Outing</div>
          <div className={styles.modal_close} onClick={closeModalJoinOuting}>
            <IconClose></IconClose>
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.message_content} style={{ display: 'block' }}>
            Enter Your Name
            <input
              type="text"
              className={styles.join_outing_input}
              placeholder="Begin typing here"
              onChange={e => setDisplayName(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className={styles.modal_actions}>
            <div className={styles.modal_footer_btn} onClick={submit}>
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
