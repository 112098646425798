import Image from 'next/image'
import { useAppContext } from 'contexts/app_context'
import IconClose from '../icon/close'
import styles from 'styles/modal_confirm.module.css'

export default function ModalMessage() {
  const [state, dispatch] = useAppContext()
  const { showModalMsg, messageAlert } = state

  const closeModalMessage = () => {
    dispatch({ type: 'SHOW_MODAL_MESSAGE', payload: false })
  }

  return (
    <div className={styles.modal_message + (showModalMsg ? '' : ' hide')} id="modal_message">
      <div className={styles.modal_container}>
        <div className={styles.modal_header}>
          <div className={styles.modal_logo}>
            <Image src="/images/modal_logo.png" alt="Team Games Logo" width={88} height={48} />
          </div>
          <div className={styles.modal_title}>
            {messageAlert.additional ? messageAlert.additional.messageTitle : ''}
          </div>
          <div className={styles.modal_close} onClick={closeModalMessage}>
            <IconClose></IconClose>
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.message_content}>
            {messageAlert.additional ? messageAlert.additional.message : messageAlert}
          </div>
          <div className={styles.modal_actions}>
            {messageAlert.additional && messageAlert.additional.action && (
              <div
                className={styles.modal_footer_btn}
                onClick={() => {
                  messageAlert.additional.action()
                  closeModalMessage()
                }}
              >
                {messageAlert.additional.btnText}
              </div>
            )}
            <div className={styles.modal_footer_btn} onClick={closeModalMessage}>
              {messageAlert.additional ? messageAlert.additional.btnClose : 'Close'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
