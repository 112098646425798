export const IconClose = ({ color = '#2050C4' }) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="11.2031"
        y="5.24512"
        width="1.08359"
        height="8.12695"
        transform="rotate(45 11.2031 5.24512)"
        fill={color}
      />
      <rect
        width="1.08359"
        height="8.12695"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 6.01562 5.24609)"
        fill={color}
      />
      <circle cx="8.49032" cy="8.49032" r="7.03118" stroke={color} strokeWidth="1.08172" />
    </svg>
  )
}

export default IconClose
